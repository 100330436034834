<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
import type { IconName } from '#build/icons'
import type { IMissingDataResponse } from '~/composables/useAPI/types'

interface Props {
  count?: number
  title?: string
  subtitle?: string
  help?: string
  description?: string
  disclaimer?: string
  to?: string | RouteLocationRaw
  icon?: IconName
  suffix?: string
  loading?: boolean
  missingData?: IMissingDataResponse
}

withDefaults(defineProps<Props>(), {
  count: 0,
  title: undefined,
  subtitle: undefined,
  description: undefined,
  disclaimer: undefined,
  to: '',
  icon: 'house-document',
  suffix: undefined,
  loading: false,
  help: undefined,
  missingData: undefined,
})

const router = useRouter()
</script>

<template>
  <button
    type="button"
    class="border-gray-750 hover:border-primary focus:border-primary focus-visible:border-primary group flex cursor-pointer rounded-2xl border bg-gray-800 px-6 py-4 focus-visible:outline-none"
    @click="router.push(to)"
  >
    <Icon
      :name="icon"
      filled
      class="border-gray-750 bg-gray-750 text-primary mr-3 self-center rounded-2xl border p-3 text-4xl"
    />

    <!-- Main -->
    <div class="flex flex-auto flex-col gap-1">
      <!-- Count -->
      <div class="flex items-center justify-between">
        <div v-if="loading" class="mb-2 flex items-center">
          <Spinner class="fill-primary h-5 w-5" />
        </div>
        <h3 v-else class="text-2xl font-medium text-white">
          {{ new Intl.NumberFormat().format(count) }}
          <span class="text-sm">
            {{ suffix }}
          </span>
        </h3>
      </div>

      <!-- Title -->
      <h4
        class="group-hover:text-primary inline-flex items-center gap-1 font-medium"
      >
        <span v-if="help">
          <VTooltip
            class="flex w-full cursor-help"
            placement="top"
            :distance="12"
            :auto-hide="true"
          >
            <Icon name="info" filled class="text-gray-500" />

            <template #popper>
              <div class="text-xs">{{ help }}</div>
            </template>
          </VTooltip>
        </span>
        <span>{{ title }}</span>
        <span v-if="subtitle" class="text-primary text-sm font-normal">
          {{ subtitle }}
        </span>
      </h4>

      <slot name="secondaryTitle" />
    </div>

    <!-- Additional -->
    <div class="flex h-full flex-col justify-between">
      <!-- Missing Data -->
      <div class="ml-auto flex">
        <DashboardMissingData v-if="missingData" v-bind="missingData" />
      </div>

      <!-- Disclaimer -->
      <div class="ml-auto text-gray-400">
        <VTooltip
          v-if="disclaimer"
          class="cursor-help"
          placement="top"
          :distance="12"
          :auto-hide="true"
        >
          <span
            class="inline-flex items-center justify-center gap-1 px-3 py-1.5 font-medium text-gray-500 decoration-dotted"
          >
            <Icon name="info" />
            <span class="text-xs"> {{ disclaimer }} </span>
          </span>
          <template #popper>
            <div class="text-xs">
              Only leases with the required data for this calculation
            </div>
          </template>
        </VTooltip>
      </div>
    </div>
  </button>
</template>

<style lang="postcss" scoped>
.card-hover {
  @apply overflow-hidden;
  @apply before:absolute before:inset-0 before:z-[-2] before:bg-cover before:opacity-0 before:transition-all before:content-[''] hover:before:opacity-40;
  &:before {
    background-image: url('@ui/assets/icons/card-hover.svg');
  }
}
</style>
